// src/components/NotFound.js
import React from 'react';
import '../styles/pages/NotFound.css';
const NotFound = () => {
    return (
        <div className="not-found">
            <h1>404 - Страница не найдена</h1>
            <p>Извините, но страница, которую вы ищете, не существует.</p>
            <a href="/">Вернуться на главную</a>
        </div>
    );
};

export default NotFound;
