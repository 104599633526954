import React from 'react';
import telegramScreenshot from '../assets/images/course_contain/white_right_block.png'; // Большой скриншот телеграм-канала
import smallImage1 from '../assets/images/course_preview/howview.jpg'; // Маленький скрин
import smallImage2 from '../assets/images/course_preview/howanswers.jpg'; // Маленький скрин
import '../styles/components/CoursePreview.css';

const CoursePreview = () => {
    return (
        <section className="course-preview">
            <h1 className="course-preview__title">Что входит в курс</h1>
            <div className="course-preview__left">
                <div className="description-block">
                    <h3>Где находятся сливы? Как они выглядят?</h3>
                    <p>Сливы курсов располагаются в приватных телеграм-каналах, доступ к которым имеют только модераторы проекта и купившие слив.
                        Материалы выкладываем в структурированной форме строго по расписанию. ВСЕ, что есть на платформе, мы сливаем подчистую.</p>
                </div>
                <div className="image-block image-block-1"> {/* Уникальный класс для первого блока */}
                    <img src={smallImage1} alt="Material Example" />
                </div>
                <div className="description-block">
                    <h3>Содержимое слива</h3>
                    <p>Сначала идет видеоурок/вебинар — выкладываем ссылку на трансляцию (если она идет в зум или рутуб),
                        чуть позже прогружаем запись и ссылку на рутуб (если школа выкладывает записи уроков на этот видеохостинг).<br /><br />
                        После видео, как правило, идут материалы — рабочие тетради, конспекты и так далее — все выкладываем с подписями в PDF
                        формате.<br /><br /> Далее домашняя работа — также в формате PDF — файлик с заданиями, и пустой файл, который, когда сливер решит ДЗ,
                        будет заменен на файлик с решениями. В конце — дополнительные материалы, если есть (гиперссылки на карточки, доп. видео и т.д.).
                        В канале со сливом также есть меню навигации, используя которое, можно быстро перемещаться по материалам канала</p>
                </div>
                <div className="image-block image-block-2"> {/* Уникальный класс для второго блока */}
                    <img src={smallImage2} alt="Materials in PDF" />
                </div>
            </div>

            <div className="course-preview__right">
                <img src={telegramScreenshot} alt="Telegram Channel Preview" className="course-preview__image" />
            </div>
        </section>
    );
};

export default CoursePreview;
