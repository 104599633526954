import React from 'react';
import aboutImage from '../assets/images/about/alicege8.jpg';
import '../styles/components/About.css'; // Стили для блока "О проекте"

const About = () => {
    return (
        <section className="about">
            <h2>Alicege.com</h2>
            <p>Мы помогаем школьникам успешно подготовиться к ЕГЭ, предоставляя качественные курсы от 300 р.</p>
            <a href="https://t.me/alice_ege_bot" target="_blank" rel="noopener noreferrer" className="buy-course-btn">
                Купить курсы
            </a>
            <img src={aboutImage} alt="About AliceGE" className="about__image" />
        </section>
    );
};

export default About;
