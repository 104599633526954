// src/components/Footer.js
import React from 'react';
import '../styles/components/Footer.css'; // Стили для нижней шапки

const Footer = () => {
    return (
        <footer className="footer">
            <p>© 2024 Alicege. Все права защищены</p>
            {/*<p><a href="/offer">Оферта</a> | <a href="/privacy">Политика конфиденциальности</a> | <a href="/social">Соц сети</a></p>*/}
            <p><a href="https://t.me/+qgonRToYlzJmZDMy">Перейти в телеграм канал</a></p>
        </footer>
    );
};

export default Footer;
