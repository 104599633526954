import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Добавляем Яндекс.Метрику
(function(m,e,t,r,i,k,a){
    m[i] = m[i] || function(){
        (m[i].a = m[i].a || []).push(arguments);
    };
    m[i].l = 1 * new Date();

    for (var j = 0; j < document.scripts.length; j++) {
        if (document.scripts[j].src === r) { return; }
    }

    k = e.createElement(t);
    a = e.getElementsByTagName(t)[0];
    k.async = 1;
    k.src = r;
    a.parentNode.insertBefore(k, a);
})(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

/* global ym */
ym(98624261, "init", {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
});

// Основной рендер приложения
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// Если хотите начать отслеживание производительности
reportWebVitals();
