import React from 'react';
import lowcosts from '../assets/images/advantages/lowcosts.png';
import schedule from '../assets/images/advantages/schedule.png';
import handy from '../assets/images/advantages/handy.png';
import feedback from '../assets/images/advantages/feedback.png';
import savematerials from '../assets/images/advantages/savematerials.png';
import couching from '../assets/images/advantages/couching.png';
import '../styles/components/Advantages.css'; // Стили для блока "Преимущества"

const advantages = [
    {
        title: "Низкие цены",
        description: "Стоимость курсов ниже в 6-12 раз, при этом материалы те же!",
        imgSrc: lowcosts
    },
    {
        title: "Своевременная публикация",
        description: "Материалы публикуем в тот же день, что и в официальном курсе," +
            " поэтому идти строго по расписанию получится!",
        imgSrc: schedule
    },
    {
        title: "Удобное оформление",
        description: "Материалы публикуются в каналы" +
            " в структурированном виде (иногда даже понятнее, чем на платформе)",
        imgSrc: handy
    },
    {
        title: "Обратная связь",
        description: "Вас никто не оставит одного: в любой непонятной ситуации: от проблем с оплатой до" +
            "сложностей с тг каналом - вы всегда можете обратиться в техподдержку",
        imgSrc: feedback
    },
    {
        title: "Огромный опыт",
        description: "Мы можем выгрузить все материалы с платформы любой онлайн-школы," +
            " нет повода беспокоиться, что мы что-то пропустим!",
        imgSrc: savematerials
    },

    {
        title: "Ответственные кураторы",
        description: "Кураторы проверят ваши домашки, прояснят все непонятные моменты и, если надо, проведут созвон",
        imgSrc: couching
    }
];

const Advantages = () => {
    return (
        <section className="advantages">
            <h2>Преимущества</h2>
            <div className="advantages-list">
                {advantages.map((adv, index) => (
                    <div className="advantage-item" key={index}>
                        <div className="advantage-img-container">
                            <img src={adv.imgSrc} alt={adv.title} className="advantage-img" />
                        </div>
                        <div className="advantage-content">
                            <h3>{adv.title}</h3>
                            <p>{adv.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Advantages;
