import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/Header.css';
import TelegramLogo from '../assets/images/header/tg_logo.png';
// import ProjectLogo from '../assets/images/header/logo.png';

const Header = () => {

    // const handleLogoClick = () => {
    //     window.location.href = '/'; // Перенаправляем на главную страницу
    //     window.location.reload(); // Обновляем страницу
    // };
    const scrollToSection = (sectionClass) => {
        const element = document.querySelector(`.${sectionClass}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Обработчик события клика
    const handleScroll = (e, sectionClass, offset) => {
        e.preventDefault(); // предотвращаем действие по умолчанию
        console.log(`Scrolling to section: ${sectionClass} with offset: ${offset}`);
        scrollToSection(sectionClass, offset);
    };

    return (
        <header className="header">
            {/*<div className="logo-section" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>*/}
            {/*    <img src={ProjectLogo} alt="Your Logo" className="logo" />*/}
            {/*</div>*/}
            <nav className="nav-section">
                <ul className="nav-links">
                    <li>
                        <a href="#course-preview" onClick={(e) => handleScroll(e, 'course-preview', 120)}>
                            Что входит в курс
                        </a>
                    </li>
                    <li>
                        <a href="#advantages" onClick={(e) => handleScroll(e, 'advantages', 50)}>
                            Преимущества
                        </a>
                    </li>
                    <li>
                        <a href="#reviews" onClick={(e) => handleScroll(e, 'reviews', 70)}>
                            Отзывы
                        </a>
                    </li>
                    <li>
                        <a href="#faq" onClick={(e) => handleScroll(e, 'faq', 0)}>
                            Часто задаваемые вопросы
                        </a>
                    </li>
                </ul>
            </nav>
            <div className="right-section">
                <div className="action-buttons">
                    <Link to="https://t.me/alice_ege_bot" className="btn-pink">
                        Записаться на курсы
                    </Link>
                    <a href="https://t.me/+b3Kvv3uVvk9mMWMy" className="telegram-icon" target="_blank" rel="noopener noreferrer">
                        <img src={TelegramLogo} alt="Telegram" />
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Header;
