// src/pages/HomePage.js
import React from 'react';
import About from '../components/About';
import CoursePreview from '../components/CoursePreview';
import Advantages from '../components/Advantages';
import Universities from '../components/Universities';
import Reviews from '../components/Reviews';
import FAQ from '../components/FAQ';
import '../styles/pages/HomePage.css'; // Стили для главной страницы

const HomePage = () => {
    return (
        <div className="home-page">
            <About />
            <CoursePreview />
            <Advantages />
            <Universities />
            <Reviews />
            <FAQ />
        </div>
    );
};

export default HomePage;
