// src/components/FAQItem.js
import React, { useState } from 'react';
import '../styles/components/FAQ.css';

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAnswer = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="faq-item">
            <div className="faq-question" onClick={toggleAnswer}>
                {question}
            </div>
            {isOpen && (
                <div className="faq-answer" dangerouslySetInnerHTML={{ __html: answer }} />
            )}
        </div>
    );
};


export default FAQItem;