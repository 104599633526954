import React, { useState } from 'react';
import '../styles/components/Reviews.css';
import reviewImage1 from '../assets/images/reviews/review1.png';
import reviewImage2 from '../assets/images/reviews/review2.png';
import reviewImage3 from '../assets/images/reviews/review3.png';
import reviewImage4 from '../assets/images/reviews/review4.png';
import reviewImage5 from '../assets/images/reviews/review5.png';
import reviewImage6 from '../assets/images/reviews/review6.png';
import reviewImage7 from '../assets/images/reviews/review7.png';
import reviewImage8 from '../assets/images/reviews/review8.png';
import reviewImage9 from '../assets/images/reviews/review9.png';
import reviewImage10 from '../assets/images/reviews/review10.png';

const Reviews = () => {
    const reviews = [
        { id: 1, image: reviewImage1, author: 'Дарья - @destiny_30' },
        { id: 2, image: reviewImage2, author: '@nolka_0' },
        { id: 3, image: reviewImage3, author: 'Катя - @wellny' },
        { id: 4, image: reviewImage4, author: 'Ника - @Nikshka' },
        { id: 5, image: reviewImage5, author: 'Мария - @MarS_519' },
        { id: 6, image: reviewImage6, author: 'Дарья - @piankova_darya' },
        { id: 7, image: reviewImage7, author: 'Тим - @tmrrvss' },
        { id: 8, image: reviewImage8, author: 'Соня - @soffffenok' },
        { id: 9, image: reviewImage9, author: 'Лиана - @liawqui' },
        { id: 10, image: reviewImage10, author: 'Ольга - @littleol' },
    ];

    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

    const nextReview = () => {
        setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    };

    const prevReview = () => {
        setCurrentReviewIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
    };

    const currentReview = reviews[currentReviewIndex];
    const prevReviewIndex = (currentReviewIndex - 1 + reviews.length) % reviews.length;
    const nextReviewIndex = (currentReviewIndex + 1) % reviews.length;

    return (
        <section className="reviews" id="reviews">
            <h2>Отзывы</h2>
            <div className="review-container">
                <div className="review">
                    <div className="review-img-container">
                        <img
                            src={reviews[prevReviewIndex].image}
                            alt={`Review ${reviews[prevReviewIndex].id}`}
                            className="review-img prev"
                        />
                        <p>{reviews[prevReviewIndex].author}</p>
                    </div>
                    <div className="review-img-container">
                        <img
                            src={currentReview.image}
                            alt={`Review ${currentReview.id}`}
                            className="review-img current"
                        />
                        <p>{currentReview.author}</p>
                    </div>
                    <div className="review-img-container">
                        <img
                            src={reviews[nextReviewIndex].image}
                            alt={`Review ${reviews[nextReviewIndex].id}`}
                            className="review-img next"
                        />
                        <p>{reviews[nextReviewIndex].author}</p>
                    </div>
                </div>

                <div className="review-navigation">
                    <button className="review-nav-btn prev" onClick={prevReview}>
                        &#8249;
                    </button>
                    <button className="review-nav-btn next" onClick={nextReview}>
                        &#8250;
                    </button>
                </div>
            </div>
            <a href="https://t.me/+FrC_3U-yEsZhZTgy" className="review-more">
                Ознакомиться подробнее
            </a>
        </section>
    );
};

export default Reviews;
