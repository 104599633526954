// src/components/FAQ.js
import React from 'react';
import '../styles/components/FAQ.css'; // Стили для блока "FAQ"

// src/components/FAQ.js
import FAQItem from './FAQItem';

const faqData = [
    {
        question: "По каким предметам и от каких школ вы сливаете курсы?",
        answer: "Мы сливаем курсы по всем егэшным предметам, но наибольший ассортимент по биологии, химии и русскому языку. Основной упор делаем на курсы НОО и 100балльного репетитора, но в наличии также есть Соточка, Широкопояс, Пифагор и другие авторы. Весь ассортимент курсов представлен в боте"
    },
    {
        question: "Сколько стоит слив?",
        answer: "По-разному, цена варьируется в районе 250-400 рублей за месяц курса без наставника и 550-800 рублей с наставником. Цены ниже официальных в 7-10 раз. Посмотреть цену каждого курса можно в боте"
    },
    // {
    //     question: "Как выглядит слив? Что в него входит?",
    //     answer: "Сливы курсов располагаются в приватных телеграм-каналах, доступ к которым имеют только модераторы проекта и купившие слив.<br>Материалы выкладываем в структурированной форме строго по расписанию. ВСЕ, что есть на платформе, мы сливаем подчистую.<br><br> Сначала идет видеоурок/вебинар — выкладываем ссылку на трансляцию (если она идет в зум или рутуб), чуть позже прогружаем запись и ссылку на рутуб (если школа выкладывает записи уроков на этот видеохостинг). После видео, как правило, идут материалы — рабочие тетради, конспекты и так далее — все выкладываем с подписями в PDF формате. Далее домашняя работа — также в формате PDF — файлик с заданиями, и пустой файл, который, когда сливер решит ДЗ, будет заменен на файлик с решениями. В конце — дополнительные материалы, если есть (гиперссылки на карточки, доп. видео и т.д.). В канале со сливом также есть меню навигации, используя которое, можно быстро перемещаться по материалам канала"
    // },
    {
        question: "Как быстро вы выкладываете материалы?",
        answer: "Мы стараемся строго следовать расписанию курсов и выкладывать материалы без задержек в тот же день, а ответы на ДЗ через несколько дней после выхода ДЗ"
    },
    {
        question: "Какого года у вас курсы?",
        answer: "Большинство курсов в ассортименте — это курсы подготовки к ЕГЭ 2025. Но могут встречаться и к ЕГЭ 2024. Напротив каждого товара написан его учебный год, поэтому перепутать не получится! Но все же успешно подготовиться можно и по прошлогодним курсам!"
    },
    {
        question: "Кто такой наставник? Что входит в тариф с наставником?",
        answer: `Все наставники — это студенты, сдавшие ЕГЭ по своему предмету на 85+ и прошедшие отбор. На некоторые курсы можно выбрать тариф с наставником. Покупая его, вы получаете:
    <br><br>1) Проверку, пояснение и оценивание развернутых домашних заданий
    <br>2) Помощь с возникающими по темам купленного курса вопросами
    <br>* У некоторых наставников предусмотрены созвоны по пройденным темам и другие активности, в описании таких наставников будет пометка о дополнительных преимуществах обучения с ними
    <br><br>Услуги наставника прикрепляются только на один купленный товар и действуют в течение 2 месяцев с момента покупки
    <br>На годовых/полугодовых курсах наставника необходимо продлевать каждый месяц, если вы хотите продолжать работу с ним
    <br>Купить услуги наставника отдельно нельзя. Их можно приобрести только в комплекте с курсом из магазина
    <br>У каждого наставника есть свое описание. Выбирайте того, кто вам больше всего понравится!`
    },
    {
        question: "Как купить слив?",
        answer: `Вы можете прочитать подробную инструкцию с картинками по ссылке: 
<a href="https://telegra.ph/Instrukciya-po-oplate-v-bote-07-13" target="_blank" rel="noopener noreferrer">тык</a><br><br>

Если вкратце:<br>

1) Для начала нужно перейти в <a href="https://t.me/alice_ege_bot" target="_blank" rel="noopener noreferrer">бота</a> и нажать на кнопку 💳 Пополнить баланс на клавиатуре бота (предварительно посмотрите в разделе бота 🛒 Купить курсы цену на интересующие вас курсы и прикиньте их общую сумму)<br>

2) После чего бот попросит сумму пополнения (целое число). Вводите и отправляете число боту<br>

3) Далее он генерирует ссылку для оплаты через платежную систему. Переходите по ней, и на выбор оплачиваете либо через SberPay (вас автоматом перенесет в страницу СбербанкОнлайн), либо через СБП (в этом случае нужно оплатить по сгенерированным реквизитам, самостоятельно зайдя в приложение своего банка)<br>

4) После перевода нажимаете на сайте платежной системы кнопку Подтвердить. В течение 1-15 минут деньги должны зачислиться на ваш баланс в боте, при этом вам придет сообщение об успешном пополнении<br>

5) Свой баланс и историю покупок всегда можно посмотреть в разделе бота 👩🏼‍🎓 Личный кабинет. После успешного пополнения переходите в раздел 🛒 Купить курсы и выбираете нужный вам курс. После подтверждения своего выбора вам придет ссылка на доступ в приватный телеграм-канал<br><br>

ВАЖНО! Каждый раз платежная система генерирует НОВЫЕ реквизиты для оплаты, постоянно переводить на одни и те же нельзя!<br> Комментарии к платежу писать НЕ НАДО! Переводить нужно СТРОГО ту сумму, которая указана на сайте<br> Оплатить нужно в течение 10 МИНУТ после того, как вы получили реквизиты<br> Если не успеваете — просто заново через раздел бота 💳 Пополнить баланс сгенерируйте новую платежную ссылку!`
    },
    {
        question: "Можно ли сделать возврат/обмен?",
        answer: `К сожалению, платежная система не предусматривает вывод денег на карту, поэтому возврат средств возможен только на баланс бота, которым вы можете воспользоваться, приобретая другой нужный вам курс.

Возврат оформляем в случае, если в приватный канал выложено менее 1/3 всех предусмотренных материалов. После возврата вы будете удалены из приватного канала, а деньги поступят вам на баланс бота.

Также возможен обмен курса на любой другой с доплатой (если нужный вам курс стоит дороже) или с частичным возвратом (если нужный вам курс стоит дешевле). Обмен также можно оформить в случае, если в приватку выложено менее 1/3 от всех материалов`
    },
    {
        question: "Как долго у меня будет доступ к сливу?",
        answer: "Доступ предоставляется навсегда, или по-другому — на всю жизнь. Но как правило, большинство покидают приватные каналы самостоятельно сразу после того, как сдадут ЕГЭ :)"
    },
    {
        question: "А вы не кинете? У вас есть отзывы?",
        answer: `Мы работаем уже 3-й год, и за это время через нас прошло несколько тысяч школьников, и никто не был обманут. Поддерживать проект с большим функционалом ради того, чтобы наживиться на несколько тысяч рублей, нелогично и не имеет смысла. Нас бы просто уже не было, если бы мы занимались обманом.

Кроме того, мы уже несколько лет собираем отзывы реальных людей, которым вы можете написать и спросить лично о нашем проекте:<br>

Отзывы за 22-23 год: <a href="https://t.me/+fAwbYZV-wYQyNWY6" target="_blank" rel="noopener noreferrer">тык</a><br>

Отзывы за 23-24 год: <a href="https://t.me/+UvL3hOixY_5lNDRi" target="_blank" rel="noopener noreferrer">тык</a><br><br>

Кроме того, при боте есть чат учеников на 1500+ человек, где вы также можете подробнее узнать про нашу репутацию:<br>

Ссылка на чат: <a href="https://t.me/+PBEAWTomt60zY2Fi" target="_blank" rel="noopener noreferrer">тык</a>`
    },
    {
        question: "Можно ли на сливах подготовиться на высокие баллы?",
        answer: `ДА! И наши покупатели доказали это неоднократно! Многие учившиеся на наших сливах от звонка до звонка получили 80+, были и стобалльники — например, по химии на ЕГЭ 2024 таких около 4-5 человек. Подтверждением тому наши отзывы:<br>

Отзывы за 22-23 год: <a href="https://t.me/+fAwbYZV-wYQyNWY6" target="_blank" rel="noopener noreferrer">тык</a> <br>

Отзывы за 23-24 год: <a href="https://t.me/+UvL3hOixY_5lNDRi" target="_blank" rel="noopener noreferrer">тык</a><br><br>

Все зависит от вашего желания и усидчивости! Мы гарантируем доступ к качественным и подробным материалам, но сдать ЕГЭ на 90+ можете только вы!`
    }
];


const FAQ = () => {
    return (
        <div className="faq">
            <h2>Часто задаваемые вопросы</h2>
            {faqData.map((item, index) => (
                <FAQItem key={index} question={item.question} answer={item.answer} />
            ))}
        </div>
    );
};

export default FAQ;
