// src/components/Universities.js
import React from 'react';
import '../styles/components/Universities.css'; // Стили для блока "Учатся в этих вузах"
import hseLogo from '../assets/images/univer_logos/hse_logo.png';
import mguLogo from '../assets/images/univer_logos/mgu_logo.png';
import sechaLogo from '../assets/images/univer_logos/secha_logo.png';
import mgmsuLogo from '../assets/images/univer_logos/mgmsu_logo.png';
import rnimuLogo from '../assets/images/univer_logos/rnimu_logo.png';
import rudnLogo from '../assets/images/univer_logos/rudn_logo.png';

const Universities = () => {
    return (
        <section className="universities">
            <h2>Наши клиенты учатся в этих вузах:</h2>
            <div className="universities__logos">
                <img src={hseLogo} alt="HSE University"/>
                <img src={mguLogo} alt="MGU University"/>
                <img src={sechaLogo} alt="Sechenov University"/>
                <img src={mgmsuLogo} alt="MGMSU University"/>
                <img src={rnimuLogo} alt="RNIMU University"/>
                <img src={rudnLogo} alt="RUDN University"/>
            </div>
        </section>
    );
};

export default Universities;
